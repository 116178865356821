import {
    EXPORT_INFO,
    GET_EXPORTS,
} from "../../actions/Ecommerce/types"

const initialState = {
  item: null,
  items: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case EXPORT_INFO:
    // case EDIT_BRAND:
    // case FIND_BRAND:
    // case DELETE_BRAND:
      return {
        ...state,
        item: action.payload,
      }
    case GET_EXPORTS:
      return {
        ...state,
        items: action.payload,
      }
    default:
      return state
  }
}
