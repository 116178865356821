import React, { useState } from "react";
import Card from "../general/Card";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Popup from "./Address/popup/Index";
import RowDetails from "./RowImportDetails";

const ImportTableComponent = ({ bulkImportData }) => {
  console.log("bulkImportData=>", bulkImportData);
  const [seen, setSeen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const togglePop = () => {
    setSeen(!seen);
  };
  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "file_name",
      align: "center",
      title: "File Name",
    },
    {
      type: "type",
      sortable: true,
      title: "Type",
    },
    {
      type: "status",
      sortable: true,
      title: "Status",
      align: "center",
    },
    {
      type: "total_updated",
      sortable: true,
      title: "Object",
      align: "center",
    },
    {
      type: "source",
      title: "Source",
      align: "center",
    },
    {
      type: "user",
      title: "User",
      align: "center",
    },
    {
      type: "createdAt",
      align: "center",
      title: "Created",
      sortable: true,
      dataField: "createdAt", // Use createdAt field from bulkImportData
    },
    {
      type: "createdAt",
      align: "center",
      title: "Time",
      sortable: true,
      dataField: "createdAt", // Use createdAt field from bulkImportData
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];

  const renderShowsTotal = (start, to, total) => {
    return (
      <p>
        Showing {start} to {to} of {total} Results
      </p>
    );
  };

  // Define custom cell formatting function for action column
  const formatAction = (cell, row) => {
    // Define onClick event handler for action icon
    const handleClick = () => {
      // Set selectedRow state variable to clicked row
      setSelectedRow(row);

      // Open popup here
      togglePop();
    };

    return (
      <div onClick={handleClick}>
        {/* Replace this element with the desired action icon */}
        <i className="fa fa-pencil-square-o m-r-5" />
      </div>
    );
  };

  // Define custom cell formatting function for updates column
  const formatUpdates = (cell, row) => {
    if (row.results && row.results[0]) {
      const totalUpdated = row.results[0].total_updated;
      const totalInvalid = row.results[0].total_invalid;
      const color =
        totalUpdated === totalInvalid + totalUpdated ? "rgb(5, 164, 5)" : "red";
      return (
        <>
          <span style={{ color }}>{totalUpdated}</span>
          <span style={{ color: "rgb(5, 164, 5)" }}>{`/${
            totalInvalid + totalUpdated
          }`}</span>
        </>
      );
    }

    return "N/A";
  };

  // Define custom cell formatting function for status column
  const formatStatus = (cell, row) => {
    let color;

    switch (cell) {
      case "Partial":
        color = "orange";
        break;
      case "Completed":
        color = "#05a405";
        break;
      case "Failed":
        color = "red";
        break;
      default:
        color = "black";
        break;
    }

    return (
      <div
        className="imp-state-status"
        style={{ backgroundColor: color, color: "#fff" }}
      >
        {" "}
        <span>{cell}</span>
      </div>
    );
  };

  // Define custom cell formatting function for createdAt column
  const formatCreatedAt = (cell, row) => {
    // Convert timestamp to Date object
    const date = new Date(cell);

    // Format date as desired
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return formattedDate;
  };

  // Define custom cell formatting function for time column
  const formatTime = (cell, row) => {
    // Convert timestamp to Date object
    console.log("date formattime=>", cell);

    const date = new Date(cell);
    // Format time as desired
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return formattedTime;
  };

  // Define custom column className function for status column
  const columnClassNameFormat = (fieldValue, row, rowIdx, colIdx) => {
    // Replace this value with the desired class name
    const className = "table-centering";

    return className;
  };

  const options = {
    paginationShowsTotal: renderShowsTotal,
  };

  return (
    <div className="import-wrapper">
      {seen && (
        <Popup
          toggle={togglePop}
          component={<RowDetails rowData={selectedRow} />}
        />
      )}
      <Card name={"Import List"}>
        {bulkImportData && (
          <>
            <BootstrapTable data={bulkImportData} pagination options={options}>
              {headerList.map((header) => (
                <TableHeaderColumn
                  key={header.type}
                  isKey={header.isKey}
                  hidden={header.hidden}
                  dataSort={header.sortable}
                  dataField={header.type}
                  dataAlign={header.align}
                  // Use custom cell formatting function for updates and status columns
                  dataFormat={
                    header.type === "total_updated"
                      ? formatUpdates
                      : header.type === "status"
                      ? formatStatus
                      : header.title === "Created"
                      ? formatCreatedAt
                      : header.type === "action"
                      ? formatAction
                      : header.title === "Time"
                      ? formatTime
                      : undefined
                  }
                  // Use custom column className function for status column
                  columnClassName={
                    header.type === "status" ? columnClassNameFormat : undefined
                  }
                >
                  {header.title}
                </TableHeaderColumn>
              ))}
            </BootstrapTable>
          </>
        )}
      </Card>
    </div>
  );
};

export default ImportTableComponent;
