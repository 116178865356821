// PRODUCTS

// TODO: Change get to find
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_MASTER = "EDIT_PRODUCT_MASTER";
export const SET_PRODUCT = "SET_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const FIND_PRODUCT = "FIND_PRODUCT";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS"
export const FILTER_PRODUCTS = "FILTER_PRODUCTS"
export const FILTER_PRODUCTS_BRAND = "FILTER_PRODUCTS_BRAND"
export const FILTER_PRODUCTS_CATEGORY = "FILTER_PRODUCTS_CATEGORY"

// Groups Attributes
export const GET_GROUP_ATTRIBUTES = "GET_GROUP_ATTRIBUTES";
export const ADD_GROUP_ATTRIBUTES = "ADD_GROUP_ATTRIBUTES";
export const EDIT_GROUP_ATTRIBUTES = "EDIT_GROUP_ATTRIBUTES";
export const FIND_GROUP_ATTRIBUTES = "FIND_GROUP_ATTRIBUTES";
export const DELETE_GROUP_ATTRIBUTES = "DELETE_GROUP_ATTRIBUTES";
export const GET_FILTERED_ATTRIBUTES = "GET_FILTERED_ATTRIBUTES";

// CATEGORIES
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const FIND_CATEGORY = "FIND_CATEGORY";

// CATALOG
export const ADD_CATALOG = "ADD_CATALOG";
export const EDIT_CATALOG = "EDIT_CATALOG";
export const GET_CATALOGS = "GET_CATALOGS";
export const DELETE_CATALOG = "DELETE_CATALOG";
export const FIND_CATALOG = "FIND_CATALOG";

// BRANCH
export const ADD_BRANCH = "ADD_BRANCH";
export const EDIT_BRANCH = "EDIT_BRANCH";
export const GET_BRANCHES = "GET_BRANCHES";
export const DELETE_BRANCH = "DELETE_BRANCH";
export const FIND_BRANCH = "FIND_BRANCH";

// BRAND
export const ADD_BRAND = "ADD_BRAND";
export const EDIT_BRAND = "EDIT_BRAND";
export const GET_BRANDS = "GET_BRANDS";
export const DELETE_BRAND = "DELETE_BRAND";
export const FIND_BRAND = "FIND_BRAND";

// Media
export const ADD_MEDIA = "ADD_MEDIA";
export const EDIT_MEDIA = "EDIT_MEDIA";
export const GET_MEDIA = "GET_MEDIA";
export const FIND_MEDIA = "FIND_MEDIA";
export const DELETE_MEDIA = "DELETE_MEDIA";

//Ecommerce Attribute
export const ADD_ECOM_ATTRIBUTE = "ADD_ECOM_ATTRIBUTE";
export const EDIT_ECOM_ATTRIBUTE = "EDIT_ECOM_ATTRIBUTE";
export const GET_ECOM_ATTRIBUTES = "GET_ECOM_ATTRIBUTES";
export const DELETE_ECOM_ATTRIBUTE = "DELETE_ECOM_ATTRIBUTE";
export const FIND_ECOM_ATTRIBUTE = "FIND_ECOM_ATTRIBUTE";

// Ecommerce Group Attribute
export const ADD_ECOM_GROUP = "ADD_ECOM_GROUP";
export const EDIT_ECOM_GROUP = "ADD_ECOM_GROUP";
export const GET_ECOM_GROUPS = "GET_ECOM_GROUPS";
export const DELETE_ECOM_GROUP = "DELETE_ECOM_GROUP";
export const FIND_ECOM_GROUP = "FIND_ECOM_GROUP";

//Ecommerce Configurable Attribute
export const ADD_CONFIG_ATTRIBUTE = "ADD_CONFIG_ATTRIBUTE";
export const EDIT_CONFIG_ATTRIBUTE = "EDIT_CONFIG_ATTRIBUTE";
export const EDIT_CONFIG_ATTRIBUTES = "EDIT_CONFIG_ATTRIBUTES";
export const GET_CONFIG_ATTRIBUTES = "GET_CONFIG_ATTRIBUTES";
export const DELETE_CONFIG_ATTRIBUTE = "DELETE_CONFIG_ATTRIBUTE";
export const FIND_CONFIG_ATTRIBUTE = "FIND_CONFIG_ATTRIBUTE";

// PROMOTIONS
export const ADD_PROMOTION = "ADD_PROMOTION";
export const EDIT_PROMOTION = "EDIT_PROMOTION";
export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const DELETE_PROMOTION = "DELETE_PROMOTION";
export const FIND_PROMOTION = "FIND_PROMOTION";
export const GET_TEMPLATE_PROMOTIONS = "GET_TEMPLATE_PROMOTIONS";
export const GET_PROMOTION_TYPES = "GET_PROMOTION_TYPES";
export const SEARCH_PRODUCT_PROMOTION = "SEARCH_PRODUCT_PROMOTION";

// LANGUAGE
export const ADD_LANGUAGE = "ADD_LANGUAGE";
export const EDIT_LANGUAGE = "EDIT_LANGUAGE";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const FIND_LANGUAGE = "FIND_LANGUAGE";

// COUNTRY
export const ADD_COUNTRY = "ADD_COUNTRY";
export const EDIT_COUNTRY = "EDIT_COUNTRY";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const FIND_COUNTRY = "FIND_COUNTRY";

//BULK upload

export const BULK_UPLOAD_FILE = "BULK_UPLOAD_FILE";
export const BULK_IMPORT_PROGRESS_DATA = "BULK_IMPORT_PROGRESS_DATA";
export const BULK_IMPORT_GET_DATA = "BULK_IMPORT_GET_DATA";

// Export
export const EXPORT_INFO = "EXPORT_INFO";
export const GET_EXPORTS = "GET_EXPORTS";