import React, { useState, useEffect } from "react";
import { Card, CardTitle } from "reactstrap";
import TypeStep from "components/import-export/TypeStep";
import ProcessingStep from "./ProcessingStep";
import UploadStep from "components/import-export/UploadStep";
import CriteriaStep from "./Criteria"
import SelectAttribute from "./SelectAttribute"
import MapStep from "components/import-export/MapStep";
import ConfirmedStep from "./ConfirmedStep";
//import { getFilterdAttributes } from "redux/actions/Ecommerce/AttributeAction";
import ProgressSteps from "components/general/StepNavigation/StepIndecator";
import { connect } from "react-redux";
import { getAttributes , getFilterdAttributes} from "redux/actions/Ecommerce/AttributeAction";
import {
  getCategories,
} from "redux/actions/Ecommerce/CategoryAction";
import {
  getBrands,
} from "redux/actions/Ecommerce/BrandAction";
import { getCustomers, filterCustomers } from "redux/actions/CustomerAction";
import { getProducts, getAllProducts, filterProducts } from "redux/actions/Ecommerce/ProductAction";
import { exportData } from "redux/actions/Ecommerce/ExportAction";
import {getSegmentaions} from "redux/actions/SegmentationActions"
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import _ from "lodash"


const ExportPage = ({ onHide, ...props }) => {
  const [step, setStep] = useState("Type");
  const [collapsed, setCollapsed] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [filters, setFilters] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [allowUnmatchedColumns, setAllowUnmatchedColumns] = useState(false);
  const [updateMapdData, setMapUpdatedData] = useState(null);
  const [lastProgressPercentage, setLastProgressPercentage] = useState(0);
  const [updateWithProvidedID, setUpdateWithProvidedID] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCustomers, setIsLoadigCustomers] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const [logic, setLogic] = useState({
    operator: "all",
    logic_list: [
      {
        attribute: {
          system_name: "",
          inner_name: "",
          label: "",
          path: "",
          type: "",
          operator: [],
        },
        operator: "",
        value: "",
        child: {
          // empty: true,
          logic_list: [],
          operator: "all",
        },
      },
    ],
  })

  const business_id = "5cc2f00577ebc157b4f1c64c";
  const getAttributes = () => {
    setIsLoading(false)
try {
  if(selectedType)
    props.getFilterdAttributes(_.lowerCase(selectedType) ==="contact"? "crm": "ecommerce");
  else 
  props.getAttributes(_.lowerCase(selectedType) ==="contact"? "crm": "ecommerce");
  
} catch (error) {
  console.log(error)
}
  }


const fetchDataForExport = () => {
  setIsLoadigCustomers(true)
try {

  if(selectedType ==="Contact")
    props.getCustomers();
else {
  //props.getAllProducts()
  props.exportData()
}
  
} catch (error) {
  console.log(error)
}
  }

  const fetchFilterData = () => {
  try {
  
    //if(step === "Export Criteria") {
      setIsLoadigCustomers(true)

      if(selectedType ==="Contact")
        props.filterCustomers(JSON.stringify(filters));
    else {
      //props.filterProducts(JSON.stringify(filters))
      console.log(filters)
      props.exportData(JSON.stringify(filters))

    }
    //}
    
  } catch (error) {
    console.log(error)
  }
    }

  useEffect(() => {
    getAttributes()
  }, [isLoading, selectedType]);

  useEffect(() => {
    if(!selectedType) return;
    if(selectedType === "Product"){
      props.getCategories()
      props.getBrands();
      props.getCatalogs()
    }
    //props.getSegmentaions(business_id);
  }, [selectedType]);

  // useEffect(() => {
  //   if(!selectedType) return;

  //   fetchDataForExport()

  // }, [selectedType]);

  useEffect(() => {
    console.log("the filters")
    console.log({filters, step})

    if(filters && step === "Attribute") {
      fetchFilterData()

    }
    else if(!filters && step === "Attribute") {
      fetchDataForExport()
    }

  }, [filters,step]);


  const handleUpdate = (data) => {
    setMapUpdatedData(data);
  };

  const updateState = (input) => {
    setLogic(input);
  };
  const handleProcessImportData = (data) => {
    console.log(data)
    data.updateWithProvidedID = updateWithProvidedID;
    props.bulkProcessImportData(data);
  };

  const handleNextStep = () => {
    switch (step) {
      case "Type":
        if (selectedType) {
          setStep("Export Criteria");
        }
        break;
      case "Export Criteria":
        //if (fileUploaded) {
          setStep("Attribute");
        //}
        break;
      case "Attribute":
        //handleProcessImportData(updateMapdData);
        setStep("Processing");
        break;
      case "Processing":
        if (lastProgressPercentage === 100) {
          setStep("Confirmed");
        }
        break;
      case "Confirmed":
        setStep("Complete");
        break;
      default:
        setStep("Type");
    }
  };

  const handlePrevStep = () => {
    switch (step) {
      case "Export Criteria":
        setStep("Type");
        break;
      case "Attribute":
        setStep("Export Criteria");
        break;
      case "Processing":
        // Prevent going back from Processing step
        break;
      case "Confirmed":
        setStep("Processing");
        break;
      default:
        setStep("Type");
    }
  };

  const handleTypeSelection = (type) => {
    setSelectedType(type);
  };

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleFilters = (filter) => {
    setFilters(filter)
  }

  const handleFileUpload = async (file) => {
    let type;
    if (selectedType === "Contact") {
      type = "crm";
    } else if (selectedType === "Product") {
      type = "ecommerce";
    }
    let response = await props.bulkUploadFile(file, type);
    setFileUploaded(true);
  };

  const steps = [
    {
      step: 1,
      title: "Type",
      component: (
        <TypeStep
          onTypeSelection={handleTypeSelection}
          selectedType={selectedType}
          {...props}
        />
      ),
    },
    {
      step: 2,
      title: "Export Criteria",
      component: (
        <CriteriaStep
          selectedType={selectedType}
          logic={logic}
          updateState ={updateState}
          handleUpdate={handleUpdate}
          filters={filters}
          handleFilters={handleFilters}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isLoadingCustomers={isLoadingCustomers}
          setIsLoadigCustomers={setIsLoadigCustomers}
          {...props}
        />
      ),
    },
    {
      step: 3,
      title: "Attribute",
       component: (
         <SelectAttribute
           {...props}
           filters={filters}
           handleFilters={handleFilters}
           selectedType={selectedType}
           handleUpdate={handleUpdate}
           isFilterLoading={isFilterLoading}
           setIsFilterLoading={setIsFilterLoading}
           isLoadingCustomers={isLoadingCustomers}
           setIsLoadigCustomers={setIsLoadigCustomers}
         />
       ),
    },
    {
      step: 4,
      title: "Processing",
      component: (
        <ProcessingStep
          selectedType={selectedType}
          lastProgressPercentage={100}
          setLastProgressPercentage={setLastProgressPercentage}
          {...props}
        />
      ),
    },
    { step: 5, title: "Confirmed", component: <ConfirmedStep {...props} /> },
  ];

  return (
    <Card body>
      <CardTitle className="grid-title d-flex justify-content-between">
        <h4 onClick={onHide}>Export Files</h4>
        <i
          className={`fa f-size-20 ${
            collapsed ? "fa-angle-down" : "fa-angle-up"
          }`}
          onClick={handleCollapse}
        />
      </CardTitle>
      {!collapsed && (
        <div className="import-export">
          <div className="clearfix"></div>
          <div className="row-fluid">
            <div className="span12">
              <div className="grid simple">
                <div className="segmentation">
                  <div className="row-fluid">
                    <div className="span12">
                      <div
                        className="row main-content"
                        style={{ flexDirection: "column" }}
                      >
                        <ProgressSteps
                          progressSteps={steps}
                          currentStep={step}
                        />
                        {steps.find(({ title }) => title === step)?.component}

                        <div className="button-group">
                          <div className="btm-wr flex justify-between">
                            <div className="flex w-10 justify-between align-center">
                              {step !== "Type" && (
                                <button
                                  value="prev"
                                  className="flex align-center justify-start btn-prev"
                                  onClick={handlePrevStep}
                                >
                                  <div className="gg-chevron-left icon-mr"></div>
                                  Back
                                </button>
                              )}
                              <button value="cancel" className="btn-cancel">
                                Cancel
                              </button>
                            </div>
                            <div className="flex align-center justify-end">
                              {/* Add new element here */}
                              <div className="flex align-center next-button-imp">
                                {/* {step === "Map" && (
                                  <div className="unmatched-columns">
                                    You have{" "}
                                    {props.uploadedFile.unmatchedHeaders.length}{" "}
                                    unmatched columns. <br /> Do not import data
                                    in unmatched columns <span> </span>
                                    <input
                                      type="checkbox"
                                      checked={allowUnmatchedColumns}
                                      onChange={(e) =>
                                        setAllowUnmatchedColumns(
                                          e.target.checked
                                        )
                                      }
                                    />
                                  </div>
                                )} */}

                                <button
                                  value="next"
                                  className="flex align-center justify-end btn-next "
                                  onClick={handleNextStep}
                                >
                                  Next
                                  <div className="gg-chevron-right icon-mr"></div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};
//export default ExportPage;
const mapStateToProps = (state) => ({
    groups: state.attribute.items,
    attributes: state.ecommerceAttributes.items,
    customers: state.customer.items,
    products: state.products.items,
    segmentations: state.segmentations.items,
    categories: state.categories.items,
    brands: state.brands.items,
    catalogs: state.catalogs.items,
    exports: state.exports.items,
  });
  
  export default connect(mapStateToProps, {
    getAttributes,
    getFilterdAttributes,
    getCustomers,
    filterCustomers,
    getProducts,
    getAllProducts,
    filterProducts,
    getSegmentaions,
    getCategories,
    getBrands,
    getCatalogs,
    exportData
  })(ExportPage);
