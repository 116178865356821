import React, { useEffect } from "react";
import Algorithm from "components/general/Logic/Algorithm";
import { downloadExcel } from "react-export-table-to-excel";
import ExportButton  from "components/general/ExportButton"
import _ from "lodash"
import Loading from "components/general/Loading";



const SelectAttribute = ({
  onFileUpload,
  selectedType,
  isChecked,
  setIsChecked,
  filters,
  handleFilters,
  ...props
}) => {
  //console.log("step selectedType=>", props);
  const {customers,  products, attributes,isFilterLoading, setIsFilterLoading, isLoadingCustomers, setIsLoadigCustomers} = props

  // useEffect(() => {
  //   setIsFilterLoading(true)
  // }, []);

  // useEffect(() => {
  //   if(!selectedType) return;
  //   if(!filters) setIsFilterLoading(false)

  //   //if(props.customers || props.products) setIsFilterLoading(false)

  // }, []);
  console.log(isFilterLoading)
  console.log(props.products)

  // useEffect(() => {
  //  if(!props.customers || !props.products) return;

  //  //if(props.customers || props.products) setIsLoadigCustomers(false)

  // }, [props.customers, props.products]);

  useEffect(() => {
    if(!selectedType) return;
    if(selectedType !== "Contact") return;

    if(props.customers) setIsLoadigCustomers(false)

  }, [props.customers]);

  useEffect(() => {
    if(!selectedType) return;
    if(selectedType !== "Product") return;

    if(props.products) setIsLoadigCustomers(false)

  }, [props.products]);

  // const filterData = () => {

  // }


  //const handleFetchedData = () => {



  return (
    <div className="upload-step">
      <h2 className="upload-step-title">Select Attribute</h2>
      <p className="upload-step-subtitle">
       Select the attribute that you like to export according to export criteriathat you defined in the previous step {" "}
       
      </p>
      {/* {isLoadingCustomers && 
      <Loading /> */}

      {/* {!isLoadingCustomers &&(
         <div className="segmentation">
         <ExportButton data={handleDownloadExcel()} fileName={`${selectedType} Data.xlsx`} />
        </div> 

      )} */}
       
    </div>
  );
};

export default SelectAttribute;
