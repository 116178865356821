import {
    EXPORT_INFO,
    GET_EXPORTS,
  } from "./types";
  
  import {
    API_URL,
    CRM_LOCAL_TEST,
  } from "../../../../src/context/url";
  import fetchAPIClass from "../fetchAPI";
  
  export const getExportsData = () => (dispatch) => {
    let url = `${API_URL}/export/export/all`;
  
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_EXPORTS);
      api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };

  export const exportData = (inputs) => (dispatch) => {
    let requestBody = inputs;
    let url = `${API_URL}/export/export/product-info`;
    console.log(requestBody);
    try {
      const api = new fetchAPIClass(url, "POST", requestBody, EXPORT_INFO);
      let response = api.fetchAPI(dispatch);
    } catch (err) {
      console.log(err);
    }
  };
  